<template>
  <div class="container">
    <div class="main">
      <div class="header">
        <img :src="oss+'2023030804.jpg'" width="100%"/>
      </div>
      <div class="center">
        <van-icon name="passed" color="#f93319" size="100"/>
        <div class="t1">下单成功</div>
        <div class="text">将尽快为您安排发</div>
        <div class="text">请您注意查收物流信息</div>
      </div>
      <div class="adv">
        <img :src="oss+'2023030805.jpg'" width="100%"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    components: {},
    data () {
      return {

      }
    },
    created(){

    },
    computed:{
    },
    mounted(){

    },
    methods:{

    }
  }

</script>
<style lang="less" scoped>
  .container{background:#f1f1f1;padding:0px 20px;display:flex;justify-content:center;align-items:center;min-height:100vh;
    .main{border:1px solid #f86129;border-radius:8px;overflow:hidden;background:#fff;
      image{display:block;}
      .center{text-align:center;padding:50px 0px;
        .t1{font-size:36px;color:#f93319;margin:10px auto 20px;font-weight:700;}

      }
      .adv{padding:14px;}
    }
  }
</style>
